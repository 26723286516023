import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useSubheader} from "../../../_metronic/layout";
import {ModalProgressBar} from "../../../_metronic/_partials/controls";
import {connect, shallowEqual, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import * as auth from "../Auth";
import {createUser, updateEmployeeProfile} from "../Auth/_redux/authCrud";
import Helpers from "../../partials/Helpers";
import {actions} from "../Auth";
import {ROLES} from "../../partials/Enums";

function AddEmployeePage(props) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Add Employee");

  const dispatch = useDispatch();
  const history = useHistory();

  // Fields
  const [loading, setloading] = useState(false);

  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    let user = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      password_confirmation: values.password_confirmation,
      role: ROLES.EMPLOYEE,
    };
    setSubmitting(false);
    setStatus('');
    // Do request to your server for user update, we just imitate user update there, For example:
    createUser(user)
      .then(() => {
        history.push(`/browse-employees`);
      })
      .catch((error) => {
        setloading(false);
        setSubmitting(false);
        const errorMsg = Helpers.getErrorMsgFromApiCatch(error, 'Unable to update employee profile.')
        setStatus(errorMsg);
      });
  };
  // UI Helpers
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password_confirmation: "",
    role: ROLES.EMPLOYEE,
  };
  const Schema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    lastname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(),
    password_confirmation: Yup.string()
      .required()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, {resetForm}) => {
      resetForm();
    },
  });

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="d-flex flex-row">
          <div className="flex-row-fluid">
            <form className="card card-custom" onSubmit={formik.handleSubmit}>
              {loading && <ModalProgressBar/>}

              {/* begin::Header */}
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark">
                    Add New Employee
                  </h3>
                  <span className="text-muted font-weight-bold font-size-sm mt-1">
                Fill these details to add an employee.
              </span>
                </div>
                <div className="card-toolbar">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={
                      formik.isSubmitting || (formik.touched && !formik.isValid)
                    }
                  >
                    Save Changes
                    {formik.isSubmitting}
                  </button>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Form */}
              <div className="form">
                <div className="card-body">

                  {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                      <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                  )}
                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      First Name
                    </label>
                    <div className="col-lg-9">
                      <div>
                        <input
                          type="text"
                          placeholder="First Name"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "firstname"
                          )}`}
                          name="firstname"
                          {...formik.getFieldProps("firstname")}
                        />
                        {formik.touched.firstname && formik.errors.firstname ? (
                          <div className="invalid-feedback">{formik.errors.firstname}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Last Name
                    </label>
                    <div className="col-lg-9">
                      <div>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "lastname"
                          )}`}
                          name="lastname"
                          {...formik.getFieldProps("lastname")}
                        />
                        {formik.touched.lastname && formik.errors.lastname ? (
                          <div className="invalid-feedback">{formik.errors.lastname}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Email
                    </label>
                    <div className="col-lg-9">
                      <div>
                        <input
                          type="email"
                          placeholder="Email"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "email"
                          )}`}
                          name="email"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="invalid-feedback">{formik.errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Password
                    </label>
                    <div className="col-lg-9">
                      <div>
                        <input
                          type="password"
                          placeholder="Password"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "password"
                          )}`}
                          name="password"
                          {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="invalid-feedback">{formik.errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* begin::Form Group */}
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Confirm Password
                    </label>
                    <div className="col-lg-9">
                      <div>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          className={`form-control form-control-lg form-control-solid ${getInputClasses(
                            "password_confirmation"
                          )}`}
                          name="password_confirmation"
                          {...formik.getFieldProps("password_confirmation")}
                        />
                        {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                          <div className="invalid-feedback">{formik.errors.password_confirmation}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Form */}
            </form>
          </div>
        </div>
      </div>
</div>
);
}

export default connect(null, auth.actions)(AddEmployeePage);
