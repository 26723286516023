import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";

const CheckoutStripeForm = ({stripe, handleStripeSubmit, elements, loading, title,subscription_type,btnLabel, cancelAction}) => {
console.log(title)
  return (
    <div className="checkout-form w-100">
     <div className='mb-10'>
    
      <h4>{subscription_type} Plan :<span className='ml-2'>{title} </span> </h4>
     </div>
      <img className="mb-10" src={toAbsoluteUrl("/media/bg/stripe_secure.png")} alt="image" style={{height: "50px"}}/>
      <form onSubmit={(e) => handleStripeSubmit(e, stripe, elements)}>
        <div className="form-group">
          <CardNumberElement className="form-control form-control-solid h-auto py-5 px-6"/>
        </div>
        <div className="form-group">
          <CardExpiryElement className="form-control form-control-solid h-auto py-5 px-6"/>
        </div>
        <div className="form-group">
          <CardCVCElement className="form-control form-control-solid h-auto py-5 px-6"/>
        </div>
        <div className=" d-flex flex-wrap flex-center">
          <button type="submit" disabled={loading} className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
            <span>{btnLabel ? btnLabel : `Subscribe`}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          {
            cancelAction && (
              <button type="button" onClick={cancelAction} className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">
                Cancel
              </button>
            )
          }
          {
            !cancelAction && (
              <Link to="/auth/login">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            )
          }
        </div>
      </form>
    </div>
  )
}

export default injectStripe(CheckoutStripeForm);
