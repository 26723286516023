import {ROLES} from "./Enums";
import {getCurrentUrl, toAbsoluteUrl} from "../../_metronic/_helpers";
import React from "react";

export default {
  getRoleByUrlLocation: (location) => {
    let role = ROLES.COMPANY_ADMIN;
    const currentUrl = getCurrentUrl(location);
    if (currentUrl === '/auth/login/admin') {
      role = ROLES.SUPER_ADMIN;
    }
    if (
      currentUrl === '/auth/login/employee' ||
      currentUrl === '/auth/registration/employee' ||
      currentUrl.includes('/auth/accept-invite/employee')
    ) {
      role = ROLES.EMPLOYEE;
    }
    return role;
  },
  getLoginUrlByRoleIdLocalStorage: () => {
    const roleId = parseInt(localStorage.getItem('role'));
    switch (roleId) {
      case ROLES.SUPER_ADMIN:
        return '/auth/login/admin';
      case ROLES.COMPANY_ADMIN:
        return '/auth/login';
      case ROLES.EMPLOYEE:
        return '/auth/login/employee';
      default:
        return '/auth/login';
    }
  },
  isSuperAdmin: (roleId) => {
    roleId = roleId ? roleId : localStorage.getItem('role');
    return parseInt(roleId) === ROLES.SUPER_ADMIN;
  },
  isMember: (roleId) => {
    roleId = roleId ? roleId : localStorage.getItem('role');
    return parseInt(roleId) === ROLES.COMPANY_ADMIN;
  },
  isEmployee: (roleId) => {
    roleId = roleId ? roleId : localStorage.getItem('role');
    return parseInt(roleId) === ROLES.EMPLOYEE;
  },
  getErrorMsgFromApiCatch: (error, defaultMsg) => {
    let msg = defaultMsg;
    if (
      typeof error !== "undefined" &&
      typeof error.response !== "undefined" &&
      typeof error.response.data !== "undefined" &&
      typeof error.response.data.message !== "undefined" &&
      error.response.data.message
    ) {
      msg = error.response.data.message;
    }
    return msg;
  },
  getIfNull: (obj, key) => {
    if (typeof obj === "undefined" || obj == null) {
      return '';
    }
    return obj[key];
  },
  timeSince: (date) => {
    var seconds = Math.floor((new Date() - new Date(date)) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  },
  getNoItem: (data, message) => {
    if (typeof data === "undefined" || data.length <= 0) {
      return (
        <div className="text-center py-20">
          <h3 className=" py-5">{message}</h3>
        </div>
      );
    }
    return '';
  },
  getUserPic: (pic_url) => {
    if (pic_url) {
      return pic_url;
    }

    return toAbsoluteUrl('/media/users/default.jpg');
  },
  getFullName: (user, defaultStr = '') => {
    if (typeof user !== "undefined" && typeof user.firstname !== "undefined" && user.firstname) {
      return `${user.firstname} ${user.lastname}`;
    }
    return defaultStr;
  },
  randomString: (length) => {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(
        characters.charAt(Math.floor(Math.random() * charactersLength))
      );
    }
    return result.join('');
  }
};
