/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
import {shallowEqual, useSelector} from "react-redux";
import {ROLES} from "../../../../../app/partials/Enums";

export function HeaderMenu({layoutProps}) {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  }

  return <div
    id="kt_header_menu"
    className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
    {...layoutProps.headerMenuAttributes}
  >
    {/*begin::Header Nav*/}
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {/*begin::1 Level*/}
      <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
        <NavLink className="menu-link" to="/dashboard">
          <span className="menu-text">Dashboard</span>
          {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
        </NavLink>
      </li>
      {/*end::1 Level*/}
      {/*begin::1 Level*/}
      <li className={`menu-item menu-item-rel ${getMenuItemActive('/projects')}`}>
        <NavLink className="menu-link" to="/projects">
          <span className="menu-text">Projects</span>
          {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
        </NavLink>
      </li>
      {/*end::1 Level*/}
      {/*begin::1 Level*/}
      {
        user.role === ROLES.COMPANY_ADMIN && (
          <li className={`menu-item menu-item-rel ${getMenuItemActive('/organization/users')}`}>
            <NavLink className="menu-link" to="/organization/users">
              <span className="menu-text">My Team</span>
              {layoutProps.rootArrowEnabled && (<i className="menu-arrow"/>)}
            </NavLink>
          </li>
        )}
      {/*end::1 Level*/}
    </ul>
    {/*end::Header Nav*/}
  </div>;
}
