import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useLocation} from "react-router";
import {connect, shallowEqual, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {Link, useParams} from "react-router-dom";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {register} from "../_redux/authCrud";
import Helpers from "../../../partials/Helpers";
import {ROLES} from "../../../partials/Enums";
import {Elements, StripeProvider} from "react-stripe-elements";
import CheckoutStripeForm from "./CheckoutStripeForm";
import {actions} from "../_redux/authRedux";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  password_confirmation: "",
  acceptTerms: false,
};

function Registration(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  let role = Helpers.getRoleByUrlLocation(location);
  let {planId} = useParams();

  const {intl} = props;
  const [loading, setLoading] = useState(false);
  const [registerUserValues, setRegisterUserValues] = useState({});
  const [paymentStatus, setPaymentStatus] = useState('');
  const {plans} = useSelector(
    (state) => ({
      plans: state.auth.plans,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.requestPlans(true));
  }, []);

  const RegistrationSchema = Yup.object().shape({
    organization_name: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(200, "Maximum 200 symbols")
      .required("Organization Name is Required"),
    firstname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setSubmitting(true);
      enableLoading();
      register(
        values.organization_name,
        values.firstname,
        values.lastname,
        values.email,
        values.password,
        values.password_confirmation,
        role,
        planId
      )
        .then(({data: {result}}) => {
          const accessToken = result.accessToken;
          if (role === ROLES.COMPANY_ADMIN && !accessToken) {
            setRegisterUserValues({
              organization_name: values.organization_name,
              firstname: values.firstname,
              lastname: values.lastname,
              email: values.email,
              password: values.password,
              password_confirmation: values.password_confirmation,
              role
            });
          } else {
            props.register(accessToken);
          }
          disableLoading();
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          const errorMsg = Helpers.getErrorMsgFromApiCatch(error, 'Unable to Register you now. Please try again.')
          setStatus(errorMsg);
          disableLoading();
        });
    },
  });

  const handleStripeSubmit = async (event, stripe, elements) => {
    event.preventDefault();
    enableLoading();
    setPaymentStatus('');
    const {paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement('cardNumber'),
      billing_details: {
        name: `${registerUserValues.firstname} ${registerUserValues.lastname}`,
      },
    });
    register(
      registerUserValues.organization_name,
      registerUserValues.firstname,
      registerUserValues.lastname,
      registerUserValues.email,
      registerUserValues.password,
      registerUserValues.password_confirmation,
      registerUserValues.role,
      planId,
      paymentMethod
    )
      .then(({data: {result}}) => {
        const accessToken = result.accessToken;
        props.register(accessToken);
        disableLoading();
      })
      .catch((error) => {
        const errorMsg = Helpers.getErrorMsgFromApiCatch(error, 'Unable to Register you now. Please try again.')
        setPaymentStatus(errorMsg);
        disableLoading();
      });
  }

  return (
    <div className="login-form login-signin" style={{display: "block"}}>
      <div className="text-center mb-10 mb-lg-20">
        {
          role === ROLES.COMPANY_ADMIN && (
            <h3 className="font-size-h1">Organization Sign Up</h3>
          )
        }
        {
          role === ROLES.EMPLOYEE && (
            <h3 className="font-size-h1">Employee Sign Up</h3>
          )
        }
        {
          !registerUserValues.email && (
            <p className="text-muted font-weight-bold">
              Enter your details to create your account
            </p>
          )
        }
        {
          registerUserValues.email && (
            <p className="text-muted font-weight-bold">
              Enter your payment details to continue
            </p>
          )
        }
      </div>

      {
        !registerUserValues.email && (
          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >

            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            {/* end: Alert */}

            {/* begin: OrganizationName */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Organization Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "organization_name"
                )}`}
                name="organization_name"
                {...formik.getFieldProps("organization_name")}
              />
              {formik.touched.organization_name && formik.errors.organization_name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.organization_name}</div>
                </div>
              ) : null}
            </div>
            {/* end: OrganizationName */}

            <div className="row">
              <div className="col-md-6">
                {/* begin: Firstname */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="First name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "firstname"
                    )}`}
                    name="firstname"
                    {...formik.getFieldProps("firstname")}
                  />
                  {formik.touched.firstname && formik.errors.firstname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.firstname}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Firstname */}
              </div>
              <div className="col-md-6">
                {/* begin: Lastname */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Last name"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "lastname"
                    )}`}
                    name="lastname"
                    {...formik.getFieldProps("lastname")}
                  />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.lastname}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Lastname */}
              </div>
            </div>

            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}

            <div className="row">
              <div className="col-md-6">
                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  ) : null}
                </div>
                {/* end: Password */}
              </div>
              <div className="col-md-6">
                {/* begin: Confirm Password */}
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Confirm Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "password_confirmation"
                    )}`}
                    name="password_confirmation"
                    {...formik.getFieldProps("password_confirmation")}
                  />
                  {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.password_confirmation}
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* end: Confirm Password */}
              </div>
            </div>

            {/* begin: Terms and Conditions */}
            <div className="form-group">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  className="m-1"
                  {...formik.getFieldProps("acceptTerms")}
                />
                <a
                  href="https://www.nippybrands.com/terms-conditions/"
                  target="_blank"
                  className="mr-1"
                  rel="noopener noreferrer"
                >
                  I agree the Terms & Conditions
                </a>
                <span/>
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                </div>
              ) : null}
            </div>
            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap flex-center">
              <div className="d-inline-block my-3 mx-4">
                <button
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    !formik.values.acceptTerms
                  }
                  className="btn btn-primary font-weight-bold px-9 py-4"
                >
                  {role === ROLES.COMPANY_ADMIN && (<span>Get Started</span>)}
                  {/* {role === ROLES.COMPANY_ADMIN && (<span>Start my Free Trial</span>)} */}
                  {role !== ROLES.COMPANY_ADMIN && <span>Sign Up</span>}

                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
                <br/>
                {
                  plans && plans.length > 0 && (
                    <>
                      {/* <strong>Plan:</strong>&nbsp;&nbsp;
                      {
                        plans.map((item) => (
                          <span key={item.id} className={item.id == planId ? `` : `d-none`}>
                          {item.title} &pound;{item.stripe_amount / 100}
                        </span>
                        ))
                      } */}
                    </>
                  )
                }&nbsp;
              </div>

              <div className="d-inline-block my-3 mx-4">
                <Link to={role === ROLES.EMPLOYEE ? `/auth/login/employee` : `/auth/login`}>
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold px-9 py-4"
                  >
                    Cancel
                  </button>
                </Link>
                <br/>
                &nbsp;
              </div>
            </div>
          </form>
        )
      }

      {/* begin: Stripe Form */}
      {
        registerUserValues.email && (
          <>
            {/* begin: Alert */}
            {paymentStatus && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{paymentStatus}</div>
              </div>
            )}
            {/* end: Alert */}

            <StripeProvider
              apiKey="pk_live_51IxC6eEKcfpMOYuNR0sKXL2CCRha86uilMYlMPt3ya37PJQyRCD8H4fj9wnL14dx6QMupoYjAWju2GnuhkAhrSF700bujgdHko">
              <Elements>
                <CheckoutStripeForm 
                title={  plans && plans.length > 0 && (plans.map((item) => (
                  <span key={item.id} className={item.id == planId ? `` : `d-none`}>
                {item.title} &pound;{item.stripe_amount / 100} {item.subscription_plan === 'Monthly' ?'per month':'per annum'}
                </span>
                )))} 
                subscription_type={  plans && plans.length > 0 && (plans.map((item) => (
                  <span key={item.id} className={item.id == planId ? `` : `d-none`}>
                  {item.subscription_plan}
                </span>
                )))} 
                handleStripeSubmit={handleStripeSubmit}
                loading={loading}
                />
              </Elements>
            </StripeProvider>
          </>
        )
      }
      {/* end: Stripe Form */}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
