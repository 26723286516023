import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import Helpers from "../../../partials/Helpers";

class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem('window_reloaded')
    localStorage.removeItem('window_reloaded_employee_fav')
    localStorage.removeItem('window_reloaded_employee')
    this.props.logout();
    
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to={Helpers.getLoginUrlByRoleIdLocalStorage()} />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
