import {actions} from "../app/modules/Auth";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    let msg = 'Unable to connect you with the server.';
    if (error && typeof error.response !== "undefined") {
      if (error.response.status === 401) {
        store.dispatch(actions.logout());
      }
    }
    return Promise.reject(error)
  });
}
