export const ProjectStatusCssClasses = ["", "primary", "info", "blue", "pink", "success", "yellow"];
export const ProjectStatusTitles = ["", "Pending", "In Production", "Awaiting Approval", "In Revision", "Completed", "Cancelled"];
export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 }
];
export const initialFilter = {
  filter: {
    title: "",
    description: ""
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
