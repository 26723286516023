import axios from "axios";
import ENV from '../../../../env';

export function login(email, password, role) {
  return axios.post(`${ENV.API_URL}/users/login`, {email, password, role});
}

export function register(organization_name, firstname, lastname, email, password, password_confirmation, role, plan_id, stripePaymentMethod = '') {
  return axios.post(`${ENV.API_URL}/users/register`, {
    organization_name,
    firstname,
    lastname,
    email,
    password,
    password_confirmation,
    role,
    plan_id,
    stripePaymentMethod
  });
}

export function requestPassword(email) {
  return axios.post(`${ENV.API_URL}/users/forgot-password`, {email});
}

export function resetPasswordTokenExists(token) {
  return axios.post(`${ENV.API_URL}/users/validate-reset-password`, {token});
}

export function resetPassword(token, password, password_confirmation) {
  return axios.post(`${ENV.API_URL}/users/reset-password`, {
    token,
    password,
    password_confirmation,
  });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(`${ENV.API_URL}/users/me`);
}

export function updateProfile(values) {
  let formData = new FormData();
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      if (key === "pic" && typeof values.pic === "object" && values.pic) {
        formData.append(key, values[key]);
      } else if (key !== "pic") {
        formData.append(key, values[key]);
      }
    }
  }
  return axios.post(`${ENV.API_URL}/users/update-profile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function changePassword(values) {
  return axios.post(`${ENV.API_URL}/users/change-password`, {...values});
}

export function updateUserOrganization(values) {
  return axios.post(`${ENV.API_URL}/users/update-organization`, {...values});
}

export function getUserOrganization() {
  return axios.get(`${ENV.API_URL}/users/get-organization`);
}

export function getUserInvoices() {
  return axios.get(`${ENV.API_URL}/users/get-invoices`);
}

export function updateEmployeeProfile(values) {
  return axios.post(`${ENV.API_URL}/users/update-employee-profile`, {...values});
}

export function createUser(values) {
  return axios.post(`${ENV.API_URL}/users`, {...values});
}

export function getEmployeeProfile() {
  return axios.get(`${ENV.API_URL}/users/get-employee-profile`);
}

export function  getPlans(isPublic = false) {
  let url = 'get';
  if (isPublic) {
    url = 'public';
  }
  return  axios.get(`${ENV.API_URL}/plans/${url}`);
}

export function getIndustries() {
  return axios.get(`${ENV.API_URL}/industries/get`);
}

export function updatePaymentMethod(stripePaymentMethod) {
  return axios.post(`${ENV.API_URL}/users/update-payment-method`, {stripePaymentMethod});
}

export function getEmployees(query) {
  return axios.post(`${ENV.API_URL}/users/browse-employees`, {...query});
}

export function getFavouriteEmployees() {
  return axios.get(`${ENV.API_URL}/users/favourite`);
}

export function getBlockedEmployees() {
  return axios.get(`${ENV.API_URL}/users/blocked`);
}

export function makeFavouriteEmployee(user_id) {
  return axios.post(`${ENV.API_URL}/users/add-to-favourite`, {user_id});
}

export function blockEmployee(user_id) {
  return axios.post(`${ENV.API_URL}/users/add-to-block`, {user_id});
}

export function removeFavouriteEmployee(user_id) {
  return axios.post(`${ENV.API_URL}/users/remove-from-favourite`, {user_id});
}

export function unblockEmployee(user_id) {
  return axios.post(`${ENV.API_URL}/users/unblock`, {user_id});
}
export function deleteEmployee(user_id) {
  return axios.post(`${ENV.API_URL}/users/delete-employee`, {user_id});
}
// LOGIN As User
export function loginEmployee(user_id) {
  return axios.post(`${ENV.API_URL}/users/employee/${user_id}`);
}

export function getFavouriteClients() {
  return axios.get(`${ENV.API_URL}/users/favourite`);
}

export function getBlockedClients() {
  return axios.get(`${ENV.API_URL}/users/blocked`);
}

export function getEmployee(id) {
  return axios.get(`${ENV.API_URL}/users/employee/${id}`);
}

export function processInvite(name, email) {
  return axios.post(`${ENV.API_URL}/invite/process`, {name, email});
}

export function isInviteExists(token) {
  return axios.post(`${ENV.API_URL}/invite/isExists`, {token});
}

export function acceptInvite(token, firstname, lastname, password, password_confirmation) {
  return axios.post(`${ENV.API_URL}/invite/accept`, {
    token,
    firstname,
    lastname,
    password,
    password_confirmation,
  });
}

export function getMyInvites() {
  return axios.get(`${ENV.API_URL}/invite/my`);
}

export function getMediaLibrary(search) {
  return axios.get(`${ENV.API_URL}/mediaLibrary/get?search=${search}`);
}

export function deleteInvite(id, email) {
  return axios.delete(`${ENV.API_URL}/invite/delete/${email}/${id}`);
}

export function addProjectComment(id, randomToken, data) {
  return axios.post(
    `${ENV.API_URL}/projects/${id}/addComment/${randomToken}`,
    {comment: '', ...data}
  );
}

export function completeProject($id) {
  return axios.put(`${ENV.API_URL}/projects/${$id}/complete`, {method: "_put"});
}

export function addProjectCommentAsRevision(id, data) {
  return axios.post(
    `${ENV.API_URL}/projects/${id}/addCommentAsRevision`,
    {comment: '', ...data}
  );
}

export function updateSubscription(planId) {
  return axios.post(`${ENV.API_URL}/subscriptions/update/${planId}`, {});
}

export function cancelSubscription(planId,acceptTerms) {
  return axios.post(`${ENV.API_URL}/subscriptions/cancel/${planId}`, {acceptTerms:acceptTerms});
}

export function getDashboardStats() {
  return axios.get(`${ENV.API_URL}/dashboard/stats`);
}
