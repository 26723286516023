/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {shallowEqual, useSelector} from "react-redux";
import {ROLES} from "../../../../../app/partials/Enums";

export function AsideMenuList({layoutProps}) {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}


        {/* Content */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Content</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        {
          user.role === ROLES.SUPER_ADMIN && (
            <>
              <li
                className={`menu-item ${getMenuItemActive("/categories", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/categories">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Design/Cap-2.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Categories</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/plans", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/plans">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Design/Cap-2.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Plans</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/users", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/users">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Design/Cap-2.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Organization Users</span>
                </NavLink>
              </li>
            </>
          )
        }
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/projects", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/projects">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Design/Cap-2.svg"
                )}
              />
            </span>
            <span className="menu-text">Projects</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {
          user.role === ROLES.SUPER_ADMIN && (
            <>
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/media-library", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/media-library">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Design/Cap-2.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Media Library</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
            </>
          )
        }

        {/*begin::1 Level*/}
        {
          user.role === ROLES.EMPLOYEE && (
            <>
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text">Setting</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/employee/edit-profile", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/employee/edit-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Design/Cap-2.svg"
                )}
              />
            </span>
                  <span className="menu-text">Employer Profile</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
            </>
          )
        }
        {/*end::1 Level*/}

        {
          user.role === ROLES.SUPER_ADMIN && (
            <>
              {/* Employee */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text">Employee</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/employees/favourite", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/employees/favourite">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Box2.svg"
                )}
              />
            </span>
                  <span className="menu-text">Your Employees</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/browse-employees", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/browse-employees">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Shopping/Box2.svg"
                )}
              />
            </span>
                  <span className="menu-text">Browse Employees</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
            </>
          )
        }

        {
          user.role === ROLES.COMPANY_ADMIN && (
            <>
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/media-library", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/media-library">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Design/Cap-2.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Media Library</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
              {
                user.organization_role == 1 && (
                  <>
                    {/* Organization */}
                    {/* begin::section */}
                    <li className="menu-section ">
                      <h4 className="menu-text">Organization</h4>
                      <i className="menu-icon flaticon-more-v2"></i>
                    </li>
                    {/* end:: section */}
                    {/*begin::1 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive("/organization/profile", false)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/organization/profile">
                        <span className="svg-icon menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Box2.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">Profile</span>
                      </NavLink>
                    </li>
                    {/*end::1 Level*/}
                    {/*begin::1 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive("/organization/users", false)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/organization/users">
                        <span className="svg-icon menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">Users</span>
                      </NavLink>
                    </li>
                    {/*end::1 Level*/}
                    {/*begin::1 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive("/organization/billing", false)}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/organization/billing">
                        <span className="svg-icon menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Pound.svg"
                            )}
                          />
                        </span>
                        <span className="menu-text">Billing</span>
                      </NavLink>
                    </li>
                    {/*end::1 Level*/}
                  </>
                )
              }
            </>
          )
        }

        {/* Account */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Account</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
