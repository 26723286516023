import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useLocation} from "react-router";
import {connect} from "react-redux";
import * as Yup from "yup";
import {Link, useParams} from "react-router-dom";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import Helpers from "../../../partials/Helpers";
import {ROLES} from "../../../partials/Enums";
import {acceptInvite, isInviteExists} from "../_redux/authCrud";

const initialValues = {
  firstname: "",
  lastname: "",
  password: "",
  password_confirmation: "",
  acceptTerms: false,
};

function AcceptInvite(props) {
  const location = useLocation();
  let role = Helpers.getRoleByUrlLocation(location);
  let {token} = useParams();

  const {intl} = props;
  const [loading, setLoading] = useState(false);
  const [inviteeEmail, setInviteeEmail] = useState(false);

  useEffect(() => {
    isInviteExists(token)
      .then(({data: {result}}) => {
        setInviteeEmail(result.email);
      })
      .catch((error) => {

      });
  }, [])

  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setSubmitting(true);
      enableLoading();
      acceptInvite(token, values.firstname, values.lastname, values.password, values.password_confirmation)
        .then(({data: {result}}) => {
          const accessToken = result.accessToken;
          props.register(accessToken);
          disableLoading();
          setSubmitting(false);
        })
        .catch((error) => {
          setSubmitting(false);
          const errorMsg = Helpers.getErrorMsgFromApiCatch(error, 'Unable to Register you now. Please try again.')
          setStatus(errorMsg);
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{display: "block"}}>
      <div className="text-center mb-10 mb-lg-20">
        {
          role === ROLES.COMPANY_ADMIN && (
            <h3 className="font-size-h1">Organization Accept Invite</h3>
          )
        }
        {
          role === ROLES.EMPLOYEE && (
            <h3 className="font-size-h1">Employee Accept Invite</h3>
          )
        }
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>
      {
        !inviteeEmail && (
          <p className="lead text-center text-danger">
            Your invitation may expired or invalid. Please contact your
            organization's owner.
          </p>
        )
      }
      {
        inviteeEmail && (
          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >

            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            {/* end: Alert */}

            {/* begin: Firstname */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="First name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "firstname"
                )}`}
                name="firstname"
                {...formik.getFieldProps("firstname")}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.firstname}</div>
                </div>
              ) : null}
            </div>
            {/* end: Firstname */}

            {/* begin: Lastname */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Last name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "lastname"
                )}`}
                name="lastname"
                {...formik.getFieldProps("lastname")}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.lastname}</div>
                </div>
              ) : null}
            </div>
            {/* end: Lastname */}

            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                value={inviteeEmail}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                readOnly
              />
            </div>
            {/* end: Email */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Confirm Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password_confirmation"
                )}`}
                name="password_confirmation"
                {...formik.getFieldProps("password_confirmation")}
              />
              {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.password_confirmation}
                  </div>
                </div>
              ) : null}
            </div>
            {/* end: Confirm Password */}

            {/* begin: Terms and Conditions */}
            <div className="form-group">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  className="m-1"
                  {...formik.getFieldProps("acceptTerms")}
                />
                <Link
                  to="/terms"
                  target="_blank"
                  className="mr-1"
                  rel="noopener noreferrer"
                >
                  I agree the Terms & Conditions
                </Link>
                <span/>
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                </div>
              ) : null}
            </div>
            {/* end: Terms and Conditions */}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.values.acceptTerms
                }
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Submit
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>

              <Link to="/auth/login">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        )
      }
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(AcceptInvite));
