const ROLES = {
  SUPER_ADMIN: 1,
  COMPANY_ADMIN: 2,
  EMPLOYEE: 3
};

const PROJECT_STATUS = {
  PENDING: 1,
  PRODUCING: 2,
  AWAITING_APPROVAL: 3,
  REVISIONS: 4,
  COMPLETED: 5,
  CANCELLED: 6,
};

export {
  ROLES,
  PROJECT_STATUS
};
