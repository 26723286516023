import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import {actions} from "./modules/Auth";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ROLES} from "./partials/Enums";
import AddEmployeePage from "./modules/Employee/AddEmployeePage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const CategoriesMainPage = lazy(() =>
  import("./modules/Categories/CategoriesMainPage")
);
const PlansMainPage = lazy(() =>
  import("./modules/Plans/PlansMainPage")
);
const ProjectsMainPage = lazy(() =>
  import("./modules/Projects/ProjectsMainPage")
);
const UsersMainPage = lazy(() =>
  import("./modules/Users/UsersMainPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const OrganizationProfilePage = lazy(() =>
  import("./modules/Organization/OrganizationProfilePage")
);
const OrganizationUsersPage = lazy(() =>
  import("./modules/Organization/OrganizationUsersPage")
);
const OrganizationBillingPage = lazy(() =>
  import("./modules/Organization/OrganizationBillingPage")
);
const OrganizationBillingCancelPage = lazy(() =>
  import("./modules/Organization/OrganizationBillingCancelPage")
);
const OrganizationBillingCheckOutPage = lazy(() =>
  import("./modules/Organization/OrganizationBillingCheckOutPage")
);
const OrganizationBillingThanksPage = lazy(() =>
  import("./modules/Organization/OrganizationBillingThanksPage")
);
const EditEmployeeProfilePage = lazy(() =>
  import("./modules/Employee/EditEmployeeProfilePage")
);
const EmployeeProfilePage = lazy(() =>
  import("./modules/Employee/EmployeeProfilePage")
);
const BrowseEmployeesPage = lazy(() =>
  import("./modules/Employee/BrowseEmployeesPage")
);
const FavouriteEmployeesPage = lazy(() =>
  import("./modules/Employee/FavouriteEmployeesPage")
);
const BlockedEmployeesPage = lazy(() =>
  import("./modules/Employee/BlockedEmployeesPage")
);
const FavouriteClientsPage = lazy(() =>
  import("./modules/Client/FavouriteClientsPage")
);
const BlockedClientsPage = lazy(() =>
  import("./modules/Client/BlockedClientsPage")
);
const MediaLibraryPage = lazy(() =>
  import("./modules/MediaLibrary/MediaLibraryPage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect


  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  
  useEffect(() => {
     dispatch(actions.refreshUser());
     if (user.role === ROLES.COMPANY_ADMIN) {
        dispatch(actions.requestPlans());
        dispatch(actions.requestUserOrganization());        
    }
    if (user.role === ROLES.EMPLOYEE) {
       dispatch(actions.requestEmployeeProfile());
    }
  }, [dispatch])

  useEffect(() => {
    
     if (user?.role === ROLES.COMPANY_ADMIN) {
        dispatch(actions.requestPlans());
        dispatch(actions.requestUserOrganization());        
    }
    if (user?.role === ROLES.EMPLOYEE) {
       dispatch(actions.requestEmployeeProfile());
    }
  }, [user])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilePage} />
        <Route path="/categories" component={CategoriesMainPage} />
        <Route path="/plans" component={PlansMainPage} />
        <Route path="/projects" component={ProjectsMainPage} />
        <Route path="/users" component={UsersMainPage} />
        <Route path="/organization/profile" component={OrganizationProfilePage} />
        <Route path="/organization/users" component={OrganizationUsersPage} />
        <Route path="/organization/cancel" component={OrganizationBillingCancelPage} />
        <Route path="/organization/checkout" component={OrganizationBillingCheckOutPage} />
        <Route path="/organization/thanks" component={OrganizationBillingThanksPage} />
        <Route path="/organization/billing" component={OrganizationBillingPage} />
        <Route path="/employee/edit-profile" component={EditEmployeeProfilePage} />
        <Route path="/employees/add" component={AddEmployeePage} />
        <Route path="/employee/profile/:id" component={EmployeeProfilePage} />
        <Route path="/browse-employees" component={BrowseEmployeesPage} />
        <Route path="/employees/favourite" component={FavouriteEmployeesPage} />
        <Route path="/employees/blocked" component={BlockedEmployeesPage} />
        <Route path="/clients/favourite" component={FavouriteClientsPage} />
        <Route path="/clients/blocked" component={BlockedClientsPage} />
        <Route path="/media-library" component={MediaLibraryPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
