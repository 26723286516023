import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest, select} from "redux-saga/effects";
import * as apiAction from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  RefreshUser: "[Refresh User] Action",
  UserOrganizationRequested: "[Request  User Organization] Action",
  UserOrganizationLoaded: "[Load User Organization] User API",
  UserInvoicesRequested: "[Request  User Invoices] Action",
  UserInvoicesLoaded: "[Load User Invoices] User API",
  EmployeeProfileRequested: "[Request  Employee Profile] Action",
  EmployeeProfileLoaded: "[Load Employee Profile] User API",
  PlansRequested: "[Request  Plans] Action",
  PlansLoaded: "[Load Plans] API",
  IndustriesRequested: "[Request  Industries] Action",
  IndustriesLoaded: "[Load Industries] API",
  SetEmployeesQueryParams: "[Set Employees QueryParams] Action",
  EmployeesRequested: "[Request Employees] Action",
  EmployeesLoaded: "[Load Employees] User API",
  FavouriteEmployeesRequested: "[Request  Favourite Employees] Action",
  FavouriteEmployeesLoaded: "[Load Favourite Employees] User API",
  BlockedEmployeesRequested: "[Request  Blocked Employees] Action",
  BlockedEmployeesLoaded: "[Load Blocked Employees] User API",
  FavouriteClientsRequested: "[Request  Favourite Clients] Action",
  FavouriteClientsLoaded: "[Load Favourite Clients] User API",
  BlockedClientsRequested: "[Request  Blocked Clients] Action",
  DeleteClientsRequested: "[Request Delete Clients] Action",
  DeleteClientsLoaded: "[Request Delete Clients] User API",
  BlockedClientsLoaded: "[Load Blocked Clients] User API",
  EmployeeRequested: "[Request  Employee] Action",
  EmployeeLoaded: "[Load Employee] User API",
  MyInvitesRequested: "[Request  My Invites] Action",
  MyInvitesLoaded: "[Load My Invites] API",
  MediaLibraryRequested: "[Request  MediaLibrary] Action",
  MediaLibraryLoaded: "[Load MediaLibrary] API",
  DashboardStatsRequested: "[Request  DashboardStats] Action",
  DashboardStatsLoaded: "[Load DashboardStats] API"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  organization: undefined,
  invoices: [],
  plans: [],
  industries: [],
  employee_profile: undefined,
  employeesQueryParams: {
    filter: {
      firstname: "",
      lastname: "",
      email: "",
    },
    sortOrder: "desc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
  },
  employeeId: undefined,
  employee: {},
  employees: [],
  favouriteEmployees: [],
  blockedEmployees: [],
  favouriteClients: [],
  blockedClients: [],
  invites: [],
  mediaItems: [],
  dashboardStats: []
};

export const reducer = persistReducer(
  {storage, key: "v713-demo1-auth", whitelist: ["user", "authToken"]},
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const {authToken} = action.payload;

        return {authToken, user: undefined};
      }

      case actionTypes.Register: {
        const {authToken} = action.payload;

        return {authToken, user: undefined};
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const {user} = action.payload;
        return {...state, user};
      }

      case actionTypes.SetUser: {
        const {user} = action.payload;
        return {...state, user};
      }

      case actionTypes.PlansLoaded: {
        const {plans} = action.payload;
        console.log({plans})
        return {...state, plans};
      }
      case actionTypes.UserOrganizationLoaded: {
        const {organization} = action.payload;
        return {...state, organization};
      }

      case actionTypes.UserInvoicesLoaded: {
        const {invoices} = action.payload;
        return {...state, invoices};
      }

      case actionTypes.EmployeeProfileLoaded: {
        const {employee_profile} = action.payload;
        return {...state, employee_profile};
      }

     

      case actionTypes.IndustriesLoaded: {
        const {industries} = action.payload;
        return {...state, industries};
      }

      case actionTypes.SetEmployeesQueryParams: {
        const {employeesQueryParams} = action.payload;
        return {...state, employeesQueryParams};
      }

      case actionTypes.EmployeesLoaded: {
        const {employees} = action.payload;
        return {...state, employees};
      }

      case actionTypes.FavouriteEmployeesLoaded: {
        const {favouriteEmployees} = action.payload;
        return {...state, favouriteEmployees};
      }

      case actionTypes.BlockedEmployeesLoaded: {
        const {blockedEmployees} = action.payload;
        return {...state, blockedEmployees};
      }

      case actionTypes.FavouriteClientsLoaded: {
        const {favouriteClients} = action.payload;
        return {...state, favouriteClients};
      }

      case actionTypes.BlockedClientsLoaded: {
        const {blockedClients} = action.payload;
        return {...state, blockedClients};
      }
      
       case actionTypes.DeleteClientsLoaded: {
        const {employee} = action.payload;
        return {...state, employee};
      }

      case actionTypes.EmployeeRequested: {
        const {employeeId} = action.payload;
        return {...state, employeeId};
      }

      case actionTypes.EmployeeLoaded: {
        const {employee} = action.payload;
        return {...state, employee};
      }

      case actionTypes.MyInvitesLoaded: {
        const {invites} = action.payload;
        return {...state, invites};
      }

      case actionTypes.MediaLibraryRequested: {
        const {search} = action.payload;
        return {...state, search};
      }

      case actionTypes.MediaLibraryLoaded: {
        const {mediaItems} = action.payload;
        return {...state, mediaItems};
      }

      case actionTypes.DashboardStatsLoaded: {
        const {dashboardStats} = action.payload;
        return {...state, dashboardStats};
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({type: actionTypes.Login, payload: {authToken}}),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: {authToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: {user},
  }),
  fulfillUser: (user) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user) => ({type: actionTypes.SetUser, payload: {user}}),
  refreshUser: () => ({type: actionTypes.RefreshUser}),
  requestPlans: (isPublic = false) => ({type: actionTypes.PlansRequested, payload: {isPublic}}),
  fulfillPlans:  (plans) => ({
    type: actionTypes.PlansLoaded,
    payload: {plans}
  }),
  requestUserOrganization: () => ({type: actionTypes.UserOrganizationRequested}),
  fulfillUserOrganization: (organization) => ({type: actionTypes.UserOrganizationLoaded, payload: {organization}}),
  requestUserInvoices: () => ({type: actionTypes.UserInvoicesRequested}),
  fulfillUserInvoices: (invoices) => ({type: actionTypes.UserInvoicesLoaded, payload: {invoices}}),
  requestEmployeeProfile: () => ({type: actionTypes.EmployeeProfileRequested}),
  fulfillEmployeeProfile: (employee_profile) => ({
    type: actionTypes.EmployeeProfileLoaded,
    payload: {employee_profile}
  }),

  requestIndustries: () => ({type: actionTypes.IndustriesRequested}),
  fulfillIndustries: (industries) => ({
    type: actionTypes.IndustriesLoaded,
    payload: {industries}
  }),
  setEmployeesQueryParams: (employeesQueryParams) => ({
    type: actionTypes.SetEmployeesQueryParams,
    payload: {employeesQueryParams}
  }),
  requestEmployees: (employeesQueryParams) => ({
    type: actionTypes.EmployeesRequested,
    payload: {employeesQueryParams}
  }),
  fulfillEmployees: (employees) => ({
    type: actionTypes.EmployeesLoaded,
    payload: {employees}
  }),
  requestFavouriteEmployees: () => ({type: actionTypes.FavouriteEmployeesRequested}),
  fulfillFavouriteEmployees: (favouriteEmployees) => ({
    type: actionTypes.FavouriteEmployeesLoaded,
    payload: {favouriteEmployees}
  }),
  requestBlockedEmployees: () => ({type: actionTypes.BlockedEmployeesRequested}),
  fulfillBlockedEmployees: (blockedEmployees) => ({
    type: actionTypes.BlockedEmployeesLoaded,
    payload: {blockedEmployees}
  }),
  requestFavouriteClients: () => ({type: actionTypes.FavouriteClientsRequested}),
  fulfillFavouriteClients: (favouriteClients) => ({
    type: actionTypes.FavouriteClientsLoaded,
    payload: {favouriteClients}
  }),
  requestBlockedClients: () => ({type: actionTypes.BlockedClientsRequested}),
  requestDeleteClients: () => ({type: actionTypes.DeleteClientsRequested}),
  fulfillBlockedClients: (blockedClients) => ({
    type: actionTypes.BlockedClientsLoaded,
    payload: {blockedClients}
  }),
  requestEmployee: (employeeId) => ({
    type: actionTypes.EmployeeRequested,
    payload: {employeeId}
  }),
  fulfillEmployee: (employee) => ({
    type: actionTypes.EmployeeLoaded,
    payload: {employee}
  }),
  requestMyInvites: () => ({type: actionTypes.MyInvitesRequested}),
  fulfillMyInvites: (invites) => ({type: actionTypes.MyInvitesLoaded, payload: {invites}}),
  requestMediaLibrary: (search) => ({type: actionTypes.MediaLibraryRequested, payload: {search}}),
  fulfillMediaLibrary: (mediaItems) => ({type: actionTypes.MediaLibraryLoaded, payload: {mediaItems}}),
  requestDashboardStats: () => ({type: actionTypes.DashboardStatsRequested}),
  fulfillDashboardStats: (dashboardStats) => ({type: actionTypes.DashboardStatsLoaded, payload: {dashboardStats}})
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.RefreshUser, function* refreshUserSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data} = yield apiAction.getUserByToken();
    const {result: user} = data;
    localStorage.setItem('role', user.role);
    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.UserOrganizationRequested, function* userOrganizationRequested() {
    const {data} = yield apiAction.getUserOrganization();
    const {result: organization} = data;
    yield put(actions.fulfillUserOrganization(organization));
  });

  yield takeLatest(actionTypes.UserInvoicesRequested, function* userInvoicesRequested() {
    const {data} = yield apiAction.getUserInvoices();
    const {result: invoices} = data;
    yield put(actions.fulfillUserInvoices(invoices));
  });

  yield takeLatest(actionTypes.EmployeeProfileRequested, function* employeeProfileRequested() {
    const {data} = yield apiAction.getEmployeeProfile();
    const {result: employee_profile} = data;
    yield put(actions.fulfillEmployeeProfile(employee_profile));
  });

  yield takeLatest(actionTypes.PlansRequested, function* plansRequested({payload}) {
    let {isPublic} = payload;
    const {data} = yield apiAction.getPlans(isPublic);
    console.log({data});
    const {result: plans} = data;

    yield put(actions.fulfillPlans(plans));
  });

  yield takeLatest(actionTypes.IndustriesRequested, function* industriesRequested() {
    const {data} = yield apiAction.getIndustries();
    const {result: industries} = data;
    yield put(actions.fulfillIndustries(industries));
  });

  yield takeLatest(actionTypes.EmployeesRequested, function* employeesRequested() {
    let employeesQueryParams = yield select((state) => state.auth.employeesQueryParams);
    const {data} = yield apiAction.getEmployees(employeesQueryParams);
    const {result: employees} = data;
    yield put(actions.fulfillEmployees(employees));
  });

  yield takeLatest(actionTypes.FavouriteEmployeesRequested, function* favouriteEmployeesRequested() {
    const {data} = yield apiAction.getFavouriteEmployees();
    const {result: favouriteEmployees} = data;
    yield put(actions.fulfillFavouriteEmployees(favouriteEmployees));
  });

  yield takeLatest(actionTypes.BlockedEmployeesRequested, function* blockedEmployeesRequested() {
    const {data} = yield apiAction.getBlockedEmployees();
    const {result: blockedEmployees} = data;
    yield put(actions.fulfillBlockedEmployees(blockedEmployees));
  });

  yield takeLatest(actionTypes.FavouriteClientsRequested, function* favouriteClientsRequested() {
    const {data} = yield apiAction.getFavouriteClients();
    const {result: favouriteClients} = data;
    yield put(actions.fulfillFavouriteClients(favouriteClients));
  });

  yield takeLatest(actionTypes.BlockedClientsRequested, function* blockedClientsRequested() {
    const {data} = yield apiAction.getBlockedClients();
    const {result: blockedClients} = data;
    yield put(actions.fulfillBlockedClients(blockedClients));
  });
  


  yield takeLatest(actionTypes.EmployeeRequested, function* employeesRequested({payload}) {
    let {employeeId} = payload;
    const {data} = yield apiAction.getEmployee(employeeId);
    const {result: employee} = data;
    yield put(actions.fulfillEmployee(employee));
  });

  yield takeLatest(actionTypes.MyInvitesRequested, function* myInvitesRequested() {
    const {data} = yield apiAction.getMyInvites();
    const {result: invites} = data;
    yield put(actions.fulfillMyInvites(invites));
  });

  yield takeLatest(actionTypes.MediaLibraryRequested, function* mediaLibraryRequested({payload}) {
    let {search} = payload;
    const {data} = yield apiAction.getMediaLibrary(search);
    const {result: mediaItems} = data;
    yield put(actions.fulfillMediaLibrary(mediaItems));
  });

  yield takeLatest(actionTypes.DashboardStatsRequested, function* dashboardStatsRequested() {
    const {data} = yield apiAction.getDashboardStats();
    const {result: dashboardStats} = data;
    yield put(actions.fulfillDashboardStats(dashboardStats));
  });
}
