import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {categoriesSlice} from "../app/modules/Categories/_redux/categories/categoriesSlice";
import {plansSlice} from "../app/modules/Plans/_redux/plans/plansSlice";
import {questionsSlice} from "../app/modules/Categories/_redux/questions/questionsSlice";
import {projectsSlice} from "../app/modules/Projects/_redux/projects/projectsSlice";
import {usersSlice} from "../app/modules/Users/_redux/users/usersSlice";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  categories: categoriesSlice.reducer,
  plans: plansSlice.reducer,
  questions: questionsSlice.reducer,
  projects: projectsSlice.reducer,
  users: usersSlice.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
