import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {ROLES} from "../../../app/partials/Enums";
import {actions} from "../../../app/modules/Auth";
import {
  TilesWidget1,
  TilesWidget10,
  TilesWidget11,
  TilesWidget12,
  TilesWidget13,
  TilesWidget14,
  TilesWidget3
} from "../widgets";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_helpers";
import {ProjectStatusCssClasses, ProjectStatusTitles} from "../../../app/modules/Projects/pages/UIHelpers";

export function MyDashboard() {
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth);
  const {dashboardStats} = useSelector(
    (state) => ({
      dashboardStats: state.auth.dashboardStats,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.requestDashboardStats());
  }, []);
  return (
    <>
      <div className="row">
        {dashboardStats && dashboardStats.map((item) => {
          return (
            <div className="col-sm-4">
              <div
                className={`card card-custom gutter-b`}
                style={{ height: '150px' }}
              >
                <Link to="/projects">
                  <div className="card-body cursor-pointer">
                  <span className={`svg-icon svg-icon-3x svg-icon-${ProjectStatusCssClasses[item.status]}`}>
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")}
                    />
                  </span>
                    <span className="text-dark">
                      {ProjectStatusTitles[item.status]}
                    </span>
                    <div className="text-dark font-weight-bolder font-size-h2 mt-3">
                      {item.total}
                    </div>

                    <a
                      href="#"
                      className="text-muted text-hover-${ProjectStatusCssClasses[item.status]} font-weight-bold font-size-lg mt-1"
                    >
                      Projects
                    </a>
                  </div>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
      <div className="d-flex bg-white" style={{minHeight: '250px'}}>
        <div className="justify-content-center align-self-center w-100">
          <div className="col-12 text-center">
            <h1 style={{fontSize: "2.5rem", fontWeight: '400'}}>Welcome {user.firstname} {user.lastname} <br/>
              to Nippy Brands</h1>
            {
              user.role === ROLES.COMPANY_ADMIN
              &&
              (<Link className="btn btn-primary btn-lg mt-4" to="/projects/new">Create Project</Link>)
            }
          </div>
        </div>
      </div>
    </>
  );
}
