import React, {useState} from "react";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import {login} from "../_redux/authCrud";
import {ROLES} from "../../../partials/Enums";
import Helpers from "../../../partials/Helpers";
import {Button, ButtonGroup} from "react-bootstrap";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
  role: ROLES.EMPLOYEE,
};

function Login(props) {
  const location = useLocation();
  let role = Helpers.getRoleByUrlLocation(location);

  const {intl} = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password, role)
          .then(({data: {result}}) => {
            const accessToken = result.accessToken;
            disableLoading();
            props.login(accessToken);
          })
          .catch((error) => {
            disableLoading();
            setSubmitting(false);
            const errorMsg = Helpers.getErrorMsgFromApiCatch(error, 'Unable to Register you now. Please try again.')
            setStatus(errorMsg);
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        {
          role === ROLES.SUPER_ADMIN && (
            <>
              <h3 className="font-size-h1">Super Admin Login</h3>
              <p className="text-muted font-weight-bold">
                Enter your email and password
              </p>
            </>
          )
        }
        {
          role === ROLES.COMPANY_ADMIN && (
            <>
              <h3 className="font-size-h1">Login to Nippy Brands</h3>
              <p className="text-muted font-weight-bold">
                Don't have an account yet?
							  Sign up <a href="https://www.nippybrands.com/pricing/" target="_blank">here</a>
				
              </p>
			  <p> <a href="https://calendly.com/creativemarketingltd/nippy-brands-demo" target="_blank">Book an Appointment</a> or Call 01706318200</p>
            </>
          )
        }
        {
          role === ROLES.EMPLOYEE && (
            <>
              <h3 className="font-size-h1">Employee Login</h3>
              <p className="text-muted font-weight-bold">
                Enter your email and password
              </p>
            </>
          )
        }
      </div>
      {/* end::Head */}

      {
        role !== ROLES.COMPANY_ADMIN
        &&
        (
          <ButtonGroup size="lg" className="w-100 mb-10">
            <Button className={role === ROLES.SUPER_ADMIN ? "p-0" : "btn-outline-primary p-0"}>
              <Link to="/auth/login/admin" className="font-color-inherit btn-lg">
                SUPER ADMIN
              </Link>
            </Button>
            <Button className={role === ROLES.EMPLOYEE ? "p-0" : "btn-outline-primary p-0"}
                    to="/auth/login/employee">
              <Link to="/auth/login/employee" className="font-color-inherit btn-lg">
                EMPLOYEE
              </Link>
            </Button>
          </ButtonGroup>
        )
      }

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON"/>
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
