/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {Link, Switch, Redirect} from "react-router-dom";
import {getCurrentUrl, toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ContentRoute} from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import AcceptInvite from "./AcceptInvite";
import ResetPassword from "./ResetPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Helpers from "../../../partials/Helpers";
import {ROLES} from "../../../partials/Enums";

export function AuthPage() {
  const location = useLocation();
  const today = new Date().getFullYear();
  let role = Helpers.getRoleByUrlLocation(location);
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          {
            role === ROLES.COMPANY_ADMIN && (
              <div
                className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/media/bg/login_01.jpg")})`,
                  backgroundSize: `100% 100%`,
                }}
              >
                {/*begin: Aside Container*/}
                <div className="d-flex flex-row-fluid flex-column justify-content-between">
                  {/* start:: Aside header */}
                  <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                    <img
                      alt="Logo"
                      className="max-h-130px"
                      src={toAbsoluteUrl("/media/logos/login_04.png")}
                    />
                  </Link>
                  {/* end:: Aside header */}

                  {/* start:: Aside content */}
                  <div className="flex-column-fluid d-flex flex-column justify-content-center">
                    <h3 className="font-size-h1 mb-5 text-white">
                      Welcome to Nippy Brands!
                    </h3>
                    <p className="font-weight-lighter text-white opacity-80">
                      Nippy Brands is the ultimate on-demand content solution. Login to your portal to create your
                      content requests. <a href="https://www.nippybrands.com/pricing/" target="_blank" className="text-white">
                        <span className="font-size-h6 mb-5 text-white">View all packages here</span>
                      </a>
                    </p>
                    <img
                      alt="Login"
                      style={{maxHeight: '385px'}}
                      src={toAbsoluteUrl("/media/bg/login_08.png")}
                    />
                  </div>
                  {/* end:: Aside content */}

                  {/* start:: Aside footer for desktop */}
                  <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                    <div className="opacity-70 font-weight-bold	text-dark">
                      &copy;{" "}{today.toString()} Nippy Brands
                    </div>
                    <div className="d-flex">
                      <a href="https://www.nippybrands.com/privacy-policy/" target="_blank" className="text-dark">
                        Privacy
                      </a>
                      <a href="https://www.nippybrands.com#designsamples" target="_blank" className="text-dark ml-10">
                        Contact
                      </a>
                    </div>
                  </div>
                  {/* end:: Aside footer for desktop */}
                </div>
                {/*end: Aside Container*/}
              </div>
            )
          }
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {
              role === ROLES.COMPANY_ADMIN && (
                <div className="flex-column-auto d-lg-flex justify-content-between">
                  <div className="opacity-70 font-weight-bold	text-muted">
                    &nbsp;
                  </div>
                  <div className="d-flex">
                    <a href="https://www.nippybrands.com/about-us/" target="_blank" className="text-dark-65">
                      About us
                    </a>
                    <a href="https://www.nippybrands.com/pricing/" target="_blank" className="text-dark-65 ml-10">
                      Pricing Plans
                    </a>
                    <a href="https://www.nippybrands.com/how-it-works/" target="_blank" className="text-dark-65 ml-10">
                      How it works
                    </a>
                    <a href="https://www.nippybrands.com/examples/" target="_blank" className="text-dark-65 ml-10 d-none d-md-block">
                      Portfolio
                    </a>
                    <a href="https://www.nippybrands.com/blog/" target="_blank" className="text-dark-65 ml-10 d-none d-md-block">
                      Blog
                    </a>
                    <a href="https://www.nippybrands.com/demo/" target="_blank" className="text-dark-65 ml-10 d-none d-md-block">
                      Book Demo
                    </a>
                  </div>
                </div>
              )
            }
            {/*end::Content header*/}
            {/*begin::Content header*/}
            {
              role !== ROLES.COMPANY_ADMIN && (
                <div
                  className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                  <span className="font-weight-bold text-dark-50">Want to see organization view?</span>
                  <Link
                    to="/auth/login"
                    className="font-weight-bold ml-2"
                  >
                    GO BACK
                  </Link>
                </div>
              )
            }
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className={`d-flex flex-column-fluid flex-center mt-30 mt-lg-0`}>
              <Switch>
                <ContentRoute path="/auth/login/admin" component={Login}/>
                <ContentRoute path="/auth/login/" component={Login}/>
                <ContentRoute path="/auth/login/employee" component={Login}/>
                <ContentRoute path="/auth/registration/:planId" component={Registration}/>
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute path="/auth/accept-invite/member/:token" component={AcceptInvite}/>
                <ContentRoute path="/auth/reset-password/:token" component={ResetPassword}/>
                <Redirect from="/auth" exact={true} to="/auth/login"/>
                <Redirect to={Helpers.getLoginUrlByRoleIdLocalStorage()}/>
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div
              className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy;{" "}{today.toString()} Nippy Brands
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
